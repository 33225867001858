<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="ShoppingRequisitionTypeHelper" header="Buscar ShoppingRequisitionType" :headers="headers" :rows="entities" @selected="selectEntity" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Tipo de Requisicion</h2>
                <BasicFormToolbar @new="openNew" @save="save" @list="openTable" :actions="['new', 'save', 'list']" />
                <Fieldset :toggleable="true" legend="Informacion">
                    <div class="formgrid grid p-fluid">
                        <FormInputText wrapperClass="'field col-12 md:col-6 xl:col-6'" label="Nombre" v-model="entity.name" :valid="validate.validations.name" />

                        <FormInputText wrapperClass="'field col-12 md:col-6 xl:col-6'" label="Descripcion" v-model="entity.key_name" :valid="validate.validations.key_name" />
                    </div>
                </Fieldset>
                <br>
                <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
                <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" />
            </div>
        </div>
    </div>
</template>
<script>
import { ShoppingRequisitionType } from '../../../models/compras/ShoppingRequisitionType';
import { FilterMatchMode } from 'primevue/api';
import { HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';

import FormInputText from '../../../components/general/FormInputText.vue';

import Helper from '../../../components/general/HelperDialog.vue';

export default {
    mixins: [Session],
    data() {
        return {
            entity: null,
            entities: [],
            // URL para subir el importador**/
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            ShoppingRequisitionTypeHelper: {
                visible: false,
            },
            filters: {},
            // Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera*/
            // 'required' de tipo 'text'*/
            rules: [new Rule({ name: 'name' }), new Rule({ name: 'key_name' })],
            // Aqui agregamos el estatus de la validacion, inicializar en las props 'null'*/
            // y el valid general en false(por que no esta validado aun)*/
            validate: {
                valid: false,
                validations: {
                    name: null,
                    key_name: null,
                },
            },
            //      Headers donde pondremos el nombre y el valor del datatable*/
            headers: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('Descripcion', 'key_name')],
            loading: false,
        };
    },
    components: {
        Loader,
        BasicFormToolbar,
        BasicDatatable,
        ConfirmDeleteDialog,
        Helper,

        FormInputText,
    },
    created() {
        console.log(this.session);
        this.entity = new ShoppingRequisitionType(this.session);
        this.uploadURL = this.$config.api_route + 'Shopping/ShoppingRequisitionType/Import/' + this.session.empresa + '/' + this.session.sucursal + '/' + this.session.usuario;
        this.initFilters();
    },
    async mounted() {
        await this.refresh();
    },

    methods: {
        async openTable(){
            this.entities = await this.entity.all();
        },
        openNew() {
            this.entity = new ShoppingRequisitionType(this.session);
            this.newDialog = true;
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
            try {
                //      * Validacion de form */
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                // Si el id es != 0 entonces actualizamos, si no, guardamos*/
                if (this.entity.id && this.entity.id > 0) {
                    // Actualizamos*/
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    // Modificamos el listado pah*/
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    // Creamos uno nuevo*/
                    let entity = await this.entity.save();
                    // Agregamos un dato extra */
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }
                this.entity = new ShoppingRequisitionType(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        selectEntity(ShoppingRequisitionType) {
            this.entity = fillObject(this.entity, ShoppingRequisitionType);
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.newDialog = true;
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },

        async deleteEntity() {
            try {
                this.loading = true;
                // Eliminamos de la base  */
                await this.entity.delete();
                //* Eliminamos de la vista*/
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                // Limpiamos la entidad */
                this.entity = new ShoppingRequisitionType(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploadError() {
            this.$toast.add(new ErrorToast('Error al intentar importar archivo'));
        },
        async refresh() {
            this.loading = true;
            try {
                //this.entities = await this.entity.all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script> 
 
<style scoped lang="scss">
</style > 
  
